<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Family Profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Edit your profile image and name.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Profile
        <div class="d-flex justify-center">
          <editable-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <v-row class="mt-2">
          <v-col cols="12" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              ID
            </div>
            <v-text-field
              label="ID"
              solo
              flat
              outlined
              dense
              v-model="profile.login_id"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Name
            </div>
            <v-text-field
              label="Name"
              solo
              flat
              outlined
              dense
              v-model="profile.name"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Email
            </div>
            <v-text-field
              label="Email"
              solo
              flat
              outlined
              dense
              v-model="profile.email"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Phone Number
            </div>
            <v-text-field
              label="Phone"
              solo
              flat
              outlined
              dense
              v-model="profile.phone"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Gender
            </div>
            <v-select
              solo
              flat
              outlined
              dense
              v-model="profile.gender"
              :items="genderOptions"
              class="mb-2 w-100"
              style="font-family:'Poppins-Regular';"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="primary"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
        <v-btn
          color="red"
          @click="onDelete"
          class="white--text"
          width="150"
          :loading="deleting"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    family: Object,
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      deleting: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: (value) => !!value || "Required.",
      },
      selectedImage: null,
      profile: {},
      genderOptions: ["Male", "Female", ""],
    };
  },
  methods: {
    ...mapActions("family", {
      updateProfile: "editFamily",
      deleteFamily: "deleteFamily",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      console.log("save");
      if (!this.profile.name) {
        this.message = "Please add your first name.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }

      formData.append("_id", this.profile._id);
      formData.append("name", this.profile.name);
      formData.append("gender", this.profile.gender ? this.profile.gender : "");

      if (this.profile.email) {
        formData.append("email", this.profile.email);
      }
      if (this.profile.phone) {
        formData.append("phone", this.profile.phone);
      }
      this.loading = true;
      this.updateProfile(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    },
    onDelete() {
      this.deleting = true;
      this.deleteFamily(this.profile)
        .then(() => {
          this.deleting = false;
          this.onClose();
        })
        .catch((error) => {
          this.deleting = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
  },
  mounted() {
    this.profile = { ...this.family };
  },
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.text-transform-none {
  text-transform: none;
}
</style>
